import { createStore } from 'vuex'
import { breadcrumbs } from './breadcrumbs'
import { edicoes } from './edicoes'
import { inscricao } from './inscricao'

export default createStore({
  state: {
    cpfUser: null,
    hcaptcha: null,
  },
  getters: {
  },
  mutations: {
    SET_CPF(state, cpf) {
      state.cpfUser = cpf
    },
    SET_HCAPTCH(state, hcaptcha) {
      state.hcaptcha = hcaptcha
    },
  },
  actions: {
    setCpf({ commit }, cpf) {
      commit('SET_CPF', cpf)
    },
    setHcaptcha({ commit }, hcaptcha) {
      commit('SET_HCAPTCH', hcaptcha)
    },
  },
  modules: {
    breadcrumbs,
    edicoes,
    inscricao,
  }
})
