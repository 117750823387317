<template>
  <v-app id="inspire">
    <div v-if="viewR" class="view-info">
      {{ messageR }}
    </div>
    <v-app-bar class="header" :class="{ 'view-info-offset': viewR }">
      <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-img :src="require('@/assets/img/logo-w.png')" max-height="28" width="80"></v-img>
      </v-toolbar-title>
      <template v-slot:append>
        <div class="user-header">
          <v-icon class="icon-user-header" icon="mdi-account-box"></v-icon>
          <v-menu>
            <template v-slot:activator="{ props }">
              <!-- <v-btn icon="mdi-dots-vertical" ></v-btn> -->
              <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list>
              <v-list-item min-width="150" @click="perfil()">
                <v-list-item-title> Perfil </v-list-item-title>
              </v-list-item>
              <v-list-item min-width="150" @click="logout()">
                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>
    <MenuItem :controle="drawer" @toggleMenu="drawer = $event" :class="{ 'view-info-menu-offset': viewR }">
    </MenuItem>

    <v-main class="main" :class="{ 'view-info-main-offset': viewR }">
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import MenuItem from "@/components/MenuItem.vue";
import { useRouter } from "vue-router";

import AuthService from "@/services/auth.service";
import { getUserInfo } from "@/util/auth";

export default defineComponent({
  name: "App",
  components: {
    MenuItem,
  },
  data: () => ({
    drawer: null,
    user: "",
    viewR: false,
    messageR: ""
  }),
  setup() {
    const router = useRouter();

    const logout = () => {
      AuthService.logout();
      router.push("/login");
    };

    const perfil = () => {
      router.push("/perfil");
    };

    const store = useStore();

    const items = computed(() => store.state.breadcrumbs.items);
    return {
      logout,
      items,
      perfil,
    };
  },
  created() {
    const data = getUserInfo();
    this.user = data.name.split(" ")[0];

    if(data?.view){
      this.viewR = true
      this.messageR = data.message
    }
  },
});
</script>
<style scoped>
.main {
  background: #e5e5e5;
}

.footer-dash {
  z-index: 1006;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
}

.header {
  background-color: #f47935;
}

.user-header {
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}

.icon-user-header {
  margin-right: 10px;
}

.view-info {
  background-image: linear-gradient(45deg, #f5cd5f 25%, #ffd563 25%, #ffd563 50%, #f5cd5f 50%, #f5cd5f 75%, #ffd563 75%, #ffd563 100%);
  background-size: 28.28px 28.28px;
  background-repeat: repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #000;
    font-size: 12px;
    padding: 6px 24px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 99999;
    display: flex;
    align-items: center;
    line-height: 10px;
}
.view-info-offset{
  top: 22px !important;
}
.view-info-main-offset{
  --v-layout-top: 86px !important;
}
</style>