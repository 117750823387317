import http from '@/http'
import { criarCookie, removerCookie } from '@/util/cookie'


class AuthService {
    login({ cpf, password }) {
        return http
            .post('public/aluno/login', {
                cpf,
                password,
            })
            .then((response) => {
                if (response.data.token) {
                    //localStorage.setItem('aluno-cuco', JSON.stringify(response.data.token))
                    criarCookie('aluno-cuco', JSON.stringify(response.data.token), 1, process.env.VUE_APP_DOMINIO);
                }
                return response.data
            })
    }

    logout() {
        //localStorage.removeItem('aluno-cuco')
        removerCookie('aluno-cuco')
    }
}

export default new AuthService()
