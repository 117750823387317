export const inscricao = {
    state: {
        dados: null,
        questionarioSocio: null,
        respostaSocio: null,
    },
    mutations: {
        SET_DADOS(state, edicoes) {
            state.dados = edicoes
        },
        SET_QUESTIONARIO(state, questionario) {
            state.questionarioSocio = questionario
        },
        SET_RESPOSTAS(state, respostas) {
            state.respostaSocio = respostas
        }
    },
    actions: {
        setDados({ commit }, data) {
            commit('SET_DADOS', data)
        },
        setQuestionario({ commit }, data) {
            commit('SET_QUESTIONARIO', data)
        },
        setRespostas({ commit }, data) {
            commit('SET_RESPOSTAS', data)
        }
    },
}