import { obterValorDoCookie } from '@/util/cookie'

export default function authHeader() {
    let token = JSON.parse(obterValorDoCookie('aluno-cuco'))

    if (token) {
        return { Authorization: 'Bearer ' + token }
    } else {
        return {}
    }
}
