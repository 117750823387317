import http from '@/http'
import authHeader from './auth-header'

class EdicoesService {
    async get() {
        const response = await http.get('aluno/edicoes', { headers: authHeader() })
        return response
    }
    async getById(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}`, { headers: authHeader() })
        return response
    }
    async getResultadpById(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/resultado`, { headers: authHeader() })
        return response
    }
    async getCertificadoById(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/certificado`, { headers: authHeader() })
        return response
    }
    async getCertificado2024ById(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/certificado/novo`, { headers: authHeader() })
        return response
    }
    async getInscricaoUserById(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/inscricao`, { headers: authHeader() })
        return response
    }
    async setInscricaoDados(idCuco, data) {
        const response = await http.put(`aluno/edicoes/${idCuco}/inscricao/dados`, data, { headers: authHeader() })
        return response
    }
    async getCursos(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/cursos`, { headers: authHeader() })
        return response
    }
    async setCursos(idCuco, data) {
        const response = await http.post(`aluno/edicoes/${idCuco}/cursos`, data, { headers: authHeader() })
        return response
    }
    async finalizarInscricao(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/inscricao/finalizar`, { headers: authHeader() })
        return response
    }
    async getStatusInscricao(idCuco) {
        const response = await http.get(`aluno/edicoes/${idCuco}/inscricao/status`, { headers: authHeader() })
        return response
    }
    async validaCertificado(data) {
        const response = await http.post(`public/certificado/validacao`, data, { headers: authHeader() })
        return response
    }
}

export default new EdicoesService()
