import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueMask from '@devindex/vue-mask';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueMask)
  .component("v-select-complete", vSelect)
  .mount('#app')
