import decode from 'jwt-decode'
import { obterValorDoCookie } from '@/util/cookie'

const AUTH_TOKEN_KEY = 'aluno-cuco'

export function getAuthToken() {
    return obterValorDoCookie(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

function getTokenExpirationDate(encodedToken) {
    try {
        let token = decode(encodedToken)
        if (!token.exp) {
            return null
        }

        let date = new Date(0)
        date.setUTCSeconds(token.exp)

        return date
    } catch (error) {
        return null
    }
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    //localStorage.removeItem(AUTH_TOKEN_KEY);
    return expirationDate < new Date()
}
