function criarCookie(nome, valor, diasExpiracao, dominio) {
    var dataExpiracao = new Date();
    dataExpiracao.setTime(dataExpiracao.getTime() + (diasExpiracao * 24 * 60 * 60 * 1000)); // Converte dias em milissegundos
    var expires = "expires=" + dataExpiracao.toUTCString();
    var cookieString = nome + "=" + valor + ";" + expires + ";path=/";

    if (dominio) {
        cookieString += ";domain=" + dominio;
    }

    document.cookie = cookieString;
}

function obterValorDoCookie(nome) {
    var nomeEQ = nome + "=";
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nomeEQ) === 0) {
            return cookie.substring(nomeEQ.length, cookie.length);
        }
    }
    return null;
}

function removerCookie(name) {
    const path = '/'
    const domain = process.env.VUE_APP_DOMINIO

    if (obterValorDoCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export { criarCookie, obterValorDoCookie, removerCookie }