<template>
  <v-navigation-drawer
    theme="dark"
    color="#343232"
    app
    width="300"
    v-model="controle_aux"
    :class="{ 'view-info-menu-offset': viewR }"
  >
    <v-list rounded>
      <div class="group-menu">
        <v-list-subheader>Dashboard</v-list-subheader>

        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          rounded="xl"
          class="btn-menu-dash"
          @click="navigate(item.link, item.ext)"
          :active="activeBtn(item.path)"
        >
          <template v-slot:prepend>
            <v-icon class="icon-btn-list-menu" :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title class="text-btn-list-menu">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getUserInfo } from "@/util/auth";
export default {
  data: () => ({
    controle_aux: false,
    drawer: null,
    viewR: false,
    items: [
      // { text: "Home", icon: "mdi-home", link: "/home", path: "home" },
      {
        text: "Edições",
        icon: "mdi-file-multiple",
        link: "/edicoes",
        path: "edicoes",
        ext: false,
      },
      {
        text: "Perfil",
        icon: "mdi-account",
        link: "/perfil",
        path: "perfil",
        ext: false,
      },
    ],
  }),
  props: {
    controle: {
      required: true,
      default: false,
    },
  },
  methods: {
    navigate(to, ext) {
      if (ext) {
        window.location.href = to;
      } else {
        this.$router.push(to);
      }
    },
    activeBtn(path) {
      if (this.$route.path.split("/")[2]) {
        if (path == "cuco-24" && path == this.$route.path.split("/")[2]) {
          return true;
        }
      } else {
        if (this.$route.path.split("/")[1] === path) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  async created() {
    this.controle_aux = this.controle;

    const data = getUserInfo();

    if(data?.view){
      this.viewR = true
    }
    // let dadosUser = JSON.parse(localStorage.getItem("user")) || [];
    // this.tipoAluno = dadosUser.tipo;
    // if(dadosUser.tipo === 'ALUNO'){
    //   this.items = [
    //     { title: "Disciplinas", icon: "mdi-paperclip", link: "/aluno/disciplinas" },
    //     { title: "Perfil", icon: "mdi-clipboard-account", link: "/perfil" },
    //   ];
    // }
    // else{
    //   this.items = [
    //     { title: "Disciplinas", icon: "mdi-paperclip", link: "/" },
    //     { title: "Questionários", icon: "mdi-help", link: "/quiz" },
    //     { title: "Perfil", icon: "mdi-clipboard-account", link: "/perfil" },
    //   ];
    // }
  },
  watch: {
    controle_aux(newValue) {
      this.$emit("toggleMenu", newValue);
    },
    controle() {
      this.controle_aux = this.controle;
    },
  },
};
</script>
<style>
.view-info-menu-offset{
  top: 86px !important;
}
</style>
<style scoped>
.text-btn-list-menu {
  font-size: 0.9rem;
  font-weight: 500;
}
.icon-btn-list-menu {
  margin-right: 18px !important;
  color: #fff;
}
.group-menu {
  padding: 6px;
  display: block;
}
.btn-menu-dash {
  color: #fff;
}
.group-menu .btn-menu-dash:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}
</style>